import { useEffect, useCallback } from 'react'
import { isMobile } from 'react-device-detect';
import { useUnityContext } from "../util/UnityContext";

const UtilBridge = () => {
    const { addEventListener, removeEventListener, sendMessage, isLoaded } = useUnityContext();
    
    const handleCopyText = useCallback(async (data: string) => {
        await navigator.clipboard.writeText(data);
    }, []);

    const handleFullscreen = useCallback(async (data: string) => {
      const element = document.documentElement;
      if (data === "Open") {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if ((element as any).mozRequestFullScreen) {
          (element as any).mozRequestFullScreen();
        } else if ((element as any).webkitRequestFullscreen) {
          (element as any).webkitRequestFullscreen();
        } else if ((element as any).msRequestFullscreen) {
          (element as any).msRequestFullscreen();
        }
      } else if (data === "Close") {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
          (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
          (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
          (document as any).msExitFullscreen();
        }
      }
    }, []);

    const handleSendTexture = useCallback(async (data: string) => {
      console.log('send texture data:', data)
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Adjust the MIME type based on your image format
      console.log(blob)
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const fileName = '中富通元宇宙截屏' + (new Date().getTime()).toString() + '.png'
      a.download = fileName; // Adjust the filename and extension based on your image format
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, []);

    const handleEquipmentType = useCallback(() => {
      console.log('加载了吗？', isLoaded)
      if (isMobile) {
        console.log('这是手机')
        sendMessage('DEVICESCONTROLLER', 'GetEquipmentType', 'mobile')
      } else {
        console.log('这是电脑')
        sendMessage('DEVICESCONTROLLER', 'GetEquipmentType', 'pc')
      }
    }, [sendMessage, isLoaded]);

    useEffect(() => {
        addEventListener("CopyText", handleCopyText);
        addEventListener("FullScreen", handleFullscreen);
        addEventListener("SendTexture", handleSendTexture);
        addEventListener("EquipmentType", handleEquipmentType);
        return () => {
          removeEventListener("CopyText", handleCopyText);
          removeEventListener("FullScreen", handleFullscreen);
          removeEventListener("SendTexture", handleSendTexture);
          removeEventListener("EquipmentType", handleEquipmentType);
        };
      }, [
        addEventListener,
        removeEventListener,
        handleCopyText,
        handleFullscreen,
        handleSendTexture,
        handleEquipmentType
      ]);

    return (
        <div id="util-bridge" style={{ display: "none" }}></div>
    );
};

export default UtilBridge;