import "../App.css";

const LoadingPage = (props: { progress: number }) => {
    const { progress } = props;
    const roundedProgress = Math.floor(progress * 100);
    console.log("progress", roundedProgress);

    return (
        <div className="loading-page">
            <img
                src="img/loading/loading-bg.png" // Replace with your background image URL
                alt="Loading background"
                className="loading-background-image"
            />
            <div
                className="progress-bar"
                style={{ width: `${roundedProgress}%` }}
            >
                <div className="percentage">{roundedProgress}%</div>
            </div>
        </div>
    );
};

export default LoadingPage;
