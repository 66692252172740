import { useEffect, useCallback } from 'react'
import '../App.css'

import {
  useJoin,
  useLocalMicrophoneTrack,
  useRemoteUsers,
  useRTCClient,
  useRemoteAudioTracks,
  RemoteAudioTrack,
} from 'agora-rtc-react'

function Live (props: { channelName: string, AppID: string, token: string, uid: string, isHost: boolean, liveEnd: boolean }) {
  const { AppID, channelName, token, uid, isHost, liveEnd } = props

  const client = useRTCClient()
  
  // 加入房间
  useJoin({
    appid: AppID,
    channel: channelName,
    token: token === '' ? null : token,
    uid: uid
  }, !liveEnd)
  
  // 远端用户
  const remoteUsers = useRemoteUsers();
  const { audioTracks: remoteAudioTracks } = useRemoteAudioTracks(remoteUsers);
  
  // 本地用户
  const { localMicrophoneTrack } = useLocalMicrophoneTrack()
  
  const firstTimeJoin = async () => {
    if (isHost) {
      await client.setClientRole('host')
      if (localMicrophoneTrack) {
        await client.publish(localMicrophoneTrack)
      }
    } else {
      await client.setClientRole('audience')
    }
  }

  const handleHostChange = useCallback(async () => {
      if (isHost) {
          console.log('成为主持人')
          await client.setClientRole('host')
          if (localMicrophoneTrack) {
            await client.publish(localMicrophoneTrack)
          }
      } else {
          console.log('成为观众')
          await client.setClientRole('audience')
          if (localMicrophoneTrack) {
            await client.unpublish(localMicrophoneTrack)
          }
      }
  }, [isHost, client, localMicrophoneTrack])

  const handleLiveEnd = useCallback(async() => {
    if (localMicrophoneTrack) {
      await client.unpublish(localMicrophoneTrack)
    }
    localMicrophoneTrack?.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  // 监听isHost变化改变身份和开关麦
  useEffect(() => {
    handleHostChange()
  }, [isHost, client, handleHostChange])

  // 处理首次加入频道
  useEffect(() => {
    firstTimeJoin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 处理直播结束
  useEffect(() => {
    if (liveEnd){
      handleLiveEnd()
    }
  }, [liveEnd, handleLiveEnd])

  return (
    <div>
      {remoteAudioTracks.map(track => (
        <RemoteAudioTrack key={track.getUserId()} play track={track} />
      ))}
    </div>
  )
}

export default Live
