const Popup = (props: {text: string, onCancelClick: () => void, onConfirmClick: () => void}) => {
    const {text, onCancelClick, onConfirmClick} = props

    return (
        <div className="popup-general">
            <div className="popup-content">
                <span className="popup-text-content">{text}</span>
                <div className="popup-button-container">
                    <button className="popup-secondary-button-general" onClick={onCancelClick}>
                        <span className="popup-secondary-button-image" />
                        <span className="popup-secondary-button-text">取消</span>
                    </button>
                    <button className="popup-button-general" onClick={onConfirmClick}>
                        <span className="popup-button-image" />
                        <span className="popup-button-text">退出</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;