import { useEffect, useCallback, useState } from "react";
import { useUnityContext } from "../util/UnityContext";
import { useEnvContext } from "../util/EnvContext";

const ConfigManager = () => {
    const { addEventListener, removeEventListener, sendMessage } =
        useUnityContext();

    const { envs, cur_env } = useEnvContext();

    const [REACT_ENV_DATA, setReactEnvData] = useState("");

    const handleGetLink = useCallback(async () => {
        console.log("监听到GetLink", cur_env);
        const reactEnvData = {
            env: cur_env,
            env_list: envs,
        };
        const data = JSON.stringify(reactEnvData);
        setReactEnvData(data);
    }, [envs, cur_env]);

    // 向Unity传环境变量
    useEffect(() => {
        if (REACT_ENV_DATA.length > 0) {
            console.log("REACT_ENV_DATA", REACT_ENV_DATA);
            sendMessage(
                "DEVICESCONTROLLER",
                "ProcessEnvVariable",
                REACT_ENV_DATA
            );
        }
    }, [REACT_ENV_DATA, sendMessage]);

    useEffect(() => {
        addEventListener("GetLink", handleGetLink);
        return () => {
            removeEventListener("GetLink", handleGetLink);
        };
    }, [addEventListener, removeEventListener, handleGetLink]);

    return <div id="config-manager" style={{ display: "none" }}></div>;
};

export default ConfigManager;
