import { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";

import Popup from './Popup'
import { useEnvContext } from "../util/EnvContext";

import {
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteUsers,
  RemoteUser,
  LocalUser,
} from "agora-rtc-react";

function Videos(props: {
  channelName: string;
  AppID: string;
  token: string;
  uid: string;
  isHost: boolean;
  userToken: string;
  inVideoCall: boolean;
  onLeaveVideoCall: (hasRecorded: boolean) => void;
}) {
  const {
    AppID,
    channelName,
    token,
    uid,
    isHost,
    userToken,
    inVideoCall,
    onLeaveVideoCall,
  } = props;
  const [myUsername, setMyUsername] = useState("");
  const [usernames, setUsernames] = useState<{ [uid: string]: string }>({});
  const [robotUid, setRobotUid] = useState("")

  const [recordingDbId, setRecordingDbId] = useState("");
  const [sid, setSid] = useState("");
  const [resourceId, setResourceId] = useState("12345");

  const [hasRecorded, setHasRecorded] = useState(false)
  const [showLeaveVideoCallPopup, setShowLeaveVideoCallPopup] = useState(false)
  const [VideoFolded, setVideoFolded] = useState(false)

  const { envs, cur_env } = useEnvContext()
  const httpBaseUrl = envs[cur_env as keyof typeof envs].api

  const apiInstance = axios.create({
    timeout: 5000, // Set a timeout for requests in milliseconds
    headers: {
      "Content-Type": "application/json", // Set your desired content type
      token: userToken, // Set your authorization token if needed
    },
  });

  // 加入房间
  useJoin(
    {
      appid: AppID,
      channel: channelName,
      token: token === "" ? null : token,
      uid: uid,
    },
    inVideoCall
  );

  // 本地用户
  // const uid = useCurrentUID()
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [isRecording, setIsRecording] = useState(false);

  navigator.mediaDevices
    .getUserMedia({ audio: true, video: false })
    .then(async (stream) => {
      console.log("Microphone access granted:", stream);
    });
  const { isLoading: isLoadingMic, localMicrophoneTrack } =
    useLocalMicrophoneTrack(micOn);
  console.log("localMicrophoneTrack", localMicrophoneTrack);

  const { isLoading: isLoadingCam, localCameraTrack } =
    useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // 远端用户
  const remoteUsers = useRemoteUsers();

  function generateRobotUid() { 
    const maxInt32 = Math.pow(2, 32) - 1; 
    const randomNumber = Math.floor(Math.random() * maxInt32); 
    const paddedNumber = randomNumber.toString().padStart(10, '0'); 
    return paddedNumber; 
  }

  // 开始录制
  const handleRecording = async () => {
    if (isRecording) {
      // 停止录制
      setIsRecording(false);
      const stopRecordingResponse = await apiInstance.post(
        `${httpBaseUrl}/meta/v1/cloudRecord/makeAcquire`,
        {
          appid: AppID,
          mode: "mix",
          resourceid: resourceId,
          sid: sid,
          cname: channelName,
          uid: robotUid,
          asyncStop: true,
          recordId: recordingDbId,
        }
      );
      console.log("stopRecordingResponse", stopRecordingResponse);
    } else {
      setIsRecording(true);
      setHasRecorded(true)
      // 生成RobotUid
      const ruid = generateRobotUid()
      console.log('生成机器人uid', ruid)
      setRobotUid(ruid)

      // 获取录制资源
      const acquireResourceId = await apiInstance.post(
        `${httpBaseUrl}/meta/v1/cloudRecord/makeAcquire`,
        { appid: AppID, cname: channelName, uid: ruid, scene: 0 }
      );
      console.log("acquireResourceId response", acquireResourceId);
      const myresourceId = acquireResourceId.data.data.resourceId;
      setResourceId(myresourceId);

      console.log("channelName", channelName);
      const getAgoraToken = await apiInstance.post(
        `${httpBaseUrl}/meta/v1/app/agora-token`,
        { channelName: channelName, uid: ruid }
      );
      console.log("getAgoraToken", getAgoraToken);
      const robotToken = getAgoraToken.data.data.agora.Token;
      // console.log('robotToken', robotToken)

      const startRecordingResponse = await apiInstance.post(
        `${httpBaseUrl}/meta/v1/cloudRecord/startRecording`,
        {
          appid: AppID,
          mode: "mix",
          resourceid: myresourceId,
          cname: channelName,
          token: robotToken,
          uid: ruid,
          streamTypes: 0,
        }
      );
      console.log("startRecording", startRecordingResponse);
      setSid(startRecordingResponse.data.data.record.sid);
      setRecordingDbId(startRecordingResponse.data.data.info._id);
    }
  };

  const handleMic = () => {
    console.log("trying to handle mic");
    setMic((a) => !a);
    // localMicrophoneTrack?.setMuted(micOn)
  };

  const handleLeaveAgora = () => {
    localCameraTrack?.close();
    localMicrophoneTrack?.close();
    if (isRecording) {
      setIsRecording(false);
      handleRecording();
    }
    onLeaveVideoCall(hasRecorded);
  };

  // 将自己的uid转换成昵称
  useEffect(() => {
    const fetchMyUsername = async () => {
      try {
        if (uid) {
          const response = await apiInstance.post(
            `${httpBaseUrl}/meta/v1/account/nickname`,
            { uid: uid }
          );

          setMyUsername(response.data.data.user.username);
        } else {
          console.log("No UID provided");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error
      }
    };
    fetchMyUsername();
  }, [apiInstance, uid, httpBaseUrl]);

  // 将remoteUsers的uid转换成username
  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const newUsers = remoteUsers.filter((user) => !usernames[user.uid]);

        // If there are new users to fetch
        if (newUsers.length > 0) {
          const requests = newUsers.map(async (user) => {
            try {
              const response = await apiInstance.post(
                `${httpBaseUrl}/meta/v1/account/nickname`,
                { uid: user.uid }
              );

              return {
                uid: user.uid,
                username: response.data.data.user.username,
              };
            } catch (error) {
              console.error(
                `Error fetching username for UID ${user.uid}:`,
                error
              );
              return { uid: user.uid, username: "Error" }; // Handle error gracefully
            }
          });

          // Wait for all requests to complete
          const newResults = await Promise.all(requests);

          // Update the state with the fetched usernames for the new users
          setUsernames((prevUsernames) => ({
            ...prevUsernames,
            ...newResults.reduce((acc, { uid, username }) => {
              acc[uid] = username;
              return acc;
            }, {} as { [uid: string]: string }),
          }));
        }
      } catch (error) {
        console.error("Error fetching usernames:", error);
        // Handle the error, set default values, or display an error message
      }
    };

    // Call the async function when remoteUsers change
    fetchUsernames();
  }, [remoteUsers, apiInstance, usernames, httpBaseUrl]);

  const deviceLoading = isLoadingMic || isLoadingCam;

  const deviceUnavailable = !localCameraTrack || !localMicrophoneTrack;

  return (
    <div style={{ position: "absolute" }}>
      {!VideoFolded && <div style={{ position: "relative" }} className="video-component">
        {deviceLoading && <div>
          <h3>接入设备中……</h3>
          <br />
        </div>}
        {deviceUnavailable && (
          <div>
            <h3>
              设备不可用，请开启{localCameraTrack ? "" : "摄像头"}
              {!localCameraTrack && !localMicrophoneTrack ? "和" : ""}
              {localMicrophoneTrack ? "" : "麦克风"}权限
            </h3>
            <br />
          </div>
        )}
        {!deviceLoading && !deviceUnavailable && (
          <div>
            <div className="agora-button-control-container">
              <h3 className="agora-button-control-left">{1 + remoteUsers.length}人视频聊天中</h3>
              <div className="agora-button-control-right fold-video" onClick={() => setVideoFolded(true)}>收起 &lt;&lt; </div>
            </div>
            <div className="video-container">
              <LocalUser
                className="videos"
                audioTrack={localMicrophoneTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                videoTrack={localCameraTrack}
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              >
                <div className="video-user-info">
                  <img
                    src={
                      micOn ? "img/video/mic-on.png" : "img/video/mic-off.png"
                    }
                    alt={micOn ? "开麦" : "闭麦"}
                  />
                  <samp className="user-name">{myUsername}</samp>
                </div>
              </LocalUser>
              {remoteUsers.map((user) => (
                <RemoteUser
                  key={user.uid}
                  className="videos"
                  user={user}
                  playVideo={true}
                  playAudio={true}
                  cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                >
                  <div className="video-user-info">
                    <img
                      src={
                        user.hasAudio
                          ? "img/video/mic-on.png"
                          : "img/video/mic-off.png"
                      }
                      alt={user.hasAudio ? "开麦" : "闭麦"}
                    />
                    <samp className="user-name">{usernames[user.uid]}</samp>
                  </div>
                </RemoteUser>
              ))}
            </div>
          </div>
        )}

        <div className="agora-button-control-container">
          <div className="agora-button-control-left">
            <button className="button-general" onClick={handleMic}>
              <img
                src={
                  micOn
                    ? "img/video/unmuted-mic-image.png"
                    : "img/video/muted-mic-image.png"
                }
                alt={micOn ? "开麦" : "闭麦"}
                style={{ height: "5vh" }}
              />
            </button>
            <button
              className="button-general"
              onClick={() => {
                setCamera((a) => !a);
              }}
            >
              <img
                src={
                  cameraOn
                    ? "img/video/camera-on-image.png"
                    : "img/video/camera-off-image.png"
                }
                alt={cameraOn ? "开摄像头" : "关摄像头"}
                style={{ height: "5vh" }}
              />
            </button>
            {isHost && (
              <button className="button-general" onClick={handleRecording}>
                {!isRecording && (
                  <div>
                    <img src={"img/main-button-bg.png"} alt={"云端录制"} style={{ height: "5vh" }}/>
                    <div className="button-text">
                      <img src={"img/video/camera-white-icon.png"} alt="" style={{ height: "2vh" }}/>
                      <span style={{ fontSize: "2vh" }}>云端录制</span>
                    </div>
                  </div>
                )}
                {isRecording && (
                  <div>
                    <img
                      src={"img/video/video-recording-bg.png"}
                      alt={"云端录制中"} style={{ height: "5vh" }}
                    />
                    <div className="button-text">
                      <img src={"img/video/camera-red-icon.png"} alt="" style={{ height: "2vh" }} />
                      <span style={{ color: "red", fontSize: "2vh" }}>云端录制中</span>
                    </div>
                  </div>
                )}
              </button>
            )}
          </div>
          <div className="agora-button-control-right">
            <button className="button-general" onClick={() => setShowLeaveVideoCallPopup(true)}>
              <img src={"img/main-button-bg.png"} alt={"退出视频"} style={{ height: "5vh" }} />
              <span className="button-text" style={{ fontSize: "2vh" }}>退出</span>
            </button>
          </div>
        </div>
      </div>}
      
      {VideoFolded && <div style={{ position: "relative" }} className="folded-video-component">
        <h4 className="agora-button-control-left">{1 + remoteUsers.length}人视频聊天中</h4>
        <LocalUser
          audioTrack={localMicrophoneTrack}
          cameraOn={cameraOn}
          micOn={micOn}
          videoTrack={localCameraTrack}
          cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
          style={{ height: "70%" }}
        >
          <div className="video-user-info">
            <img
              src={
                micOn ? "img/video/mic-on.png" : "img/video/mic-off.png"
              }
              alt={micOn ? "开麦" : "闭麦"}
            />
            <samp className="user-name">{myUsername}</samp>
          </div>
        </LocalUser>
        <div style={{ position: "absolute", color: "white", cursor: "pointer", right: "10%", bottom: "5%", fontSize: "1vw" }} onClick={() => setVideoFolded(false)}>展开 &gt;&gt; </div>
      </div>}

      {showLeaveVideoCallPopup && <Popup text={'确认离开当前视频聊天吗？'} onCancelClick={() => setShowLeaveVideoCallPopup(false)} onConfirmClick={handleLeaveAgora}/>}
    </div>
  );
}

export default Videos;
