// Import necessary React and TypeScript types
import { useEffect, useState, useCallback } from 'react';
import { useUnityContext } from '../util/UnityContext';
import AgoraRTC, { AgoraRTCProvider, IAgoraRTCClient } from 'agora-rtc-react'

import Videos from './Videos.tsx'
import Live from './Live.tsx'
import Popup from './Popup.tsx'


// Define your functional component using the FC (Functional Component) type
const Agora = () => {
    const { addEventListener, removeEventListener, sendMessage } = useUnityContext();

    const [agoraChannelName, setAgoraChannelName] = useState('')
    const [agoraAppID, setAgoraAppID] = useState('')
    const [agoraToken, setAgoraToken] = useState('')
    const [inCall, setInCall] = useState(false)
    const [uid, setUid] = useState('')
    const [userToken, setUserToken] = useState('')
    const [isRTC, setIsRTC] = useState(true)
    const [client, setClient] = useState<IAgoraRTCClient | null>(null)
    const [isHost, setIsHost] = useState(false)
    const [liveEnd, setLiveEnd] = useState(false)
    const [showSummarizeMeetingPopup, setShowSummarizeMeetingPopup] = useState(false)

    const userLeaveVideoCall = (hasRecorded: boolean) => {
        setInCall(false)
        if(hasRecorded){
            setShowSummarizeMeetingPopup(true)
        }
        sendMessage('WEBCONTROLLER', 'WebLeave')
    }

    const handleJoinAgora = useCallback((data: string) => {
        console.log('监听到JoinAgora')
        const jsonData = JSON.parse(data)
        console.log('JoinAgora收到数据', jsonData)
        const {appid, channelName, uid, token, type, host, userToken} = jsonData
        setAgoraAppID(appid)
        setAgoraChannelName(channelName)
        setAgoraToken(token)
        setUid(uid)
        setUserToken(userToken)
        setIsRTC(type === 'rtc')
        const client = AgoraRTC.createClient({ mode: type, codec: 'vp8' })
        setClient(client)
        if (!isRTC) {
            const clientRole = host ? 'host' : 'audience'
            client.setClientRole(clientRole)
            setLiveEnd(false)
            setIsHost(host === "True")
        } else {
            setIsHost(host === "True")
        }
        setInCall(true)
    }, [isRTC])

    const handleLeaveAgora = useCallback(() => {
        console.log('监听到LeaveAgora')
        setLiveEnd(true)
    }, [])

    const handleBecomeHost = useCallback((data: string) => {
        console.log('监听到BecomeHost')
        setIsHost(data === 'True')
    }, [])

    const handleSummarizeMeeting = () => {
        // 向Unity发显示机器人的信息
        setShowSummarizeMeetingPopup(false)
        sendMessage('WEBCONTROLLER', 'SummarizeMeeting')
    }

    useEffect(() => {
        addEventListener('JoinAgora', handleJoinAgora)
        addEventListener('LeaveAgora', handleLeaveAgora)
        addEventListener('BecomeHost', handleBecomeHost)
        return () => {
            removeEventListener('JoinAgora', handleJoinAgora)
            removeEventListener('LeaveAgora', handleLeaveAgora)
            removeEventListener('BecomeHost', handleBecomeHost)
        }
    }, [
        addEventListener,
        removeEventListener,
        handleJoinAgora,
        handleLeaveAgora,
        handleBecomeHost
    ])

  return (
    <div id="agora">
        {inCall && client && (
            <AgoraRTCProvider client={client}>
                {isRTC && <Videos
                    channelName={agoraChannelName}
                    AppID={agoraAppID}
                    token={agoraToken}
                    uid={uid}
                    isHost={isHost}
                    userToken={userToken}
                    inVideoCall={inCall}
                    onLeaveVideoCall={userLeaveVideoCall}
                />}
                {!isRTC && <Live
                    channelName={agoraChannelName}
                    AppID={agoraAppID}
                    token={agoraToken}
                    uid={uid}
                    isHost={isHost}
                    liveEnd={liveEnd}
                />}
            </AgoraRTCProvider>
        )}
        {showSummarizeMeetingPopup && <Popup text={'恭喜您使用了云端录制功能，现在点击确定唤醒派客可以帮您实时总结会议，并且可以实时问答'} onCancelClick={() => setShowSummarizeMeetingPopup(false)} onConfirmClick={handleSummarizeMeeting}/>}
    </div>
  );
};

export default Agora;