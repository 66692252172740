import { useEffect, useState } from 'react'
import './App.css'
import Agora from './components/Agora.tsx'
import AudioInputList from './components/AudioInputList.tsx'
import LoadingPage from './components/LoadingPage.tsx'
import ConfigManager from './components/ConfigManager.tsx'
import UtilBridge from './components/UtilBridge.tsx'
import VoiceToText from './components/VoiceToText.tsx'
import { UnityContextProvider } from './util/UnityContext.tsx';
import { EnvProvider } from './util/EnvContext.tsx'

import { Unity, useUnityContext } from 'react-unity-webgl'

function App () {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const APP_ENV = process.env.REACT_APP_ENV || "test";
  const ENV_LIST = {
    development: {
      api: "https://192.168.1.2:3667/api/plusApi",
      ws: "wss://192.168.1.2:3664/ws",
    },
    local: {
      api: "http://192.168.0.116:3690/api/plusApi",
      ws: "ws://192.168.0.116:3660/ws",
    },
    test: {
      api: "https://test1.fuzongai.com/api/plusApi",
      ws: "wss://test1.fuzongai.com/ws",
    },
    production: {
      api: "https://pro.fuzongai.com/api/plusApi",
      ws: "wss://pro.fuzongai.com/ws",
    },
  }

  const { unityProvider, addEventListener, removeEventListener, sendMessage, isLoaded, loadingProgression } =
    useUnityContext({
      loaderUrl: 'build/webglTest.loader.js',
      dataUrl: 'build/webglTest.data.unityweb',
      frameworkUrl: 'build/webglTest.framework.js.unityweb',
      codeUrl: 'build/webglTest.wasm.unityweb'
    })

  useEffect(() => {
    // A function which will update the device pixel ratio of the Unity
    // Application to match the device pixel ratio of the browser.
    const updateDevicePixelRatio = function () {
      setDevicePixelRatio(window.devicePixelRatio);
    };
    // A media matcher which watches for changes in the device pixel ratio.
    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${devicePixelRatio}dppx)`
    );
    // Adding an event listener to the media matcher which will update the
    // device pixel ratio of the Unity Application when the device pixel
    // ratio changes.
    mediaMatcher.addEventListener("change", updateDevicePixelRatio);
    return function () {
      // Removing the event listener when the component unmounts.
      mediaMatcher.removeEventListener("change", updateDevicePixelRatio);

    };
  }, [devicePixelRatio]);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  return (
    <div id="container">
      <div id='canvas-wrap'>
        {!isLoaded && <LoadingPage progress={loadingProgression}/>}
        <Unity
          unityProvider={unityProvider}
          style={{
            width: '100%',
            height: '100%',
            justifySelf: 'center',
            alignSelf: 'center',
            position: 'absolute',
            top: '0',
            left: '0'
          }}
          devicePixelRatio={devicePixelRatio}
        />
      </div>
      <EnvProvider envs={ENV_LIST} cur_env={APP_ENV}>
        <UnityContextProvider
          addEventListener={addEventListener}
          removeEventListener={removeEventListener}
          sendMessage={sendMessage}
          isLoaded={isLoaded}
        >
          <VoiceToText />
          <ConfigManager />
          <UtilBridge />
          <AudioInputList />
          <Agora />
        </UnityContextProvider>
      </EnvProvider>
    </div>
  )
}

export default App
