import React, { useEffect, useCallback, useState, useRef } from "react";
import "../App.css";
import { useUnityContext } from "../util/UnityContext";
import { useEnvContext } from "../util/EnvContext";

import RecordRTC, { StereoAudioRecorder } from "recordrtc";

function VoiceToText() {
    const recorderRef = useRef<RecordRTC | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showRecorder, setShowRecorder] = useState(false);
    const [recording, setRecording] = useState(false);
    const [status, setStatus] = useState<string | null>(null);

    const { addEventListener, removeEventListener, sendMessage } =
        useUnityContext();
    const { envs, cur_env } = useEnvContext();
    const httpBaseUrl = envs[cur_env as keyof typeof envs].api;

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,
            });
            const newRecorder = new RecordRTC(stream, {
                type: "audio",
                mimeType: "audio/webm;codecs=pcm",
                recorderType: StereoAudioRecorder,
                timeSlice: 50, // set 250 ms intervals
                desiredSampRate: 16000,
                numberOfAudioChannels: 1, // realtime requires only one channel
                bufferSize: 2048,
            });

            recorderRef.current = newRecorder;
            recorderRef.current.startRecording();
            setStatus("聆听中……");
            console.log("聆听中……");
        } catch (error) {
            console.error("录音有报错", error);
            sendMessage("ERRORCONTROLLER", "ErrorTip", "录音出错：" + error);
        }
    };

    const handleStart = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault(); // Prevent default touch behavior
        sendMessage("WEBCONTROLLER", "StopAgent");
        setRecording(true);
        startRecording();
        setStatus("准备中……");
        console.log("准备中……");
    };

    const handleEnd = useCallback(() => {
        setRecording(false);
        setStatus("识别中……");
        console.log("识别中……");
        sendMessage("WEBCONTROLLER", "GetAgentState", "interpreting");
        recorderRef.current?.stopRecording(async function () {
            let blob = recorderRef.current?.getBlob();
            const formData = new FormData();
            if (blob) {
                try {
                    formData.append("audioBlob", blob, "audio_file.wav");

                    const response = await fetch(
                        `${httpBaseUrl}/meta/v1/chat/transcriptions`,
                        {
                            method: "POST",
                            body: formData,
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to upload audio.");
                    }

                    const data = await response.json();
                    console.log("response data", data);
                    const jsonData = JSON.stringify(data);
                    sendMessage("WEBCONTROLLER", "GetRecording", jsonData);
                    setStatus("");
                } catch (error) {
                    setStatus("");
                    sendMessage(
                        "ERRORCONTROLLER",
                        "ErrorTip",
                        "语音识别出错：" + error
                    );
                }
            }
        });

        // socketRef.current?.close();
    }, [recorderRef, setRecording, setStatus, sendMessage, httpBaseUrl]);

    const handleAgentStateChange = useCallback((data: string) => {
        if (data === "AgentShow") {
            console.log("监听到AgentShow");
            setShowRecorder(true);
        } else if (data === "AgentHide") {
            console.log("监听到AgentHide");
            recorderRef.current = null;
            setShowRecorder(false);
            setStatus("");
        }
    }, []);

    const handleSendEncryption = useCallback((data: string) => {
        console.log("监听到SendEncryption", data);
        // const jsonData = JSON.parse(data)
        // setEncryptionInfo(jsonData)
    }, []);

    const handleSpeechSynthesis = useCallback((data: string) => {
        console.log("监听到SendSpeechSynthesis", data);
        // const jsonData = JSON.parse(data)
        // setSpeechSynthesisInfo(jsonData)
    }, []);

    useEffect(() => {
        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (
                buttonRef.current &&
                !buttonRef.current.contains(e.target as Node) &&
                recording
            ) {
                // Mouse or touch moved outside the button's scope
                handleEnd();
            }
        };

        document.addEventListener("mousemove", handleMove);
        document.addEventListener("touchmove", handleMove);

        return () => {
            document.removeEventListener("mousemove", handleMove);
            document.removeEventListener("touchmove", handleMove);
        };
    }, [buttonRef, handleEnd, recording]);

    useEffect(() => {
        addEventListener("AgentState", handleAgentStateChange);
        addEventListener("SendEncryption", handleSendEncryption);
        addEventListener("SendSpeechSynthesis", handleSpeechSynthesis);
        return () => {
            removeEventListener("AgentState", handleAgentStateChange);
            removeEventListener("SendEncryption", handleSendEncryption);
            removeEventListener("SendSpeechSynthesis", handleSpeechSynthesis);
        };
    }, [
        addEventListener,
        removeEventListener,
        handleAgentStateChange,
        handleSendEncryption,
        handleSpeechSynthesis,
    ]);

    return (
        <>
            <div style={{ position: "relative" }} id="voice-to-text">
                {showRecorder && (
                    <div className="recorder-button-container">
                        <div className="recording-button-group">
                            <button
                                ref={buttonRef}
                                className="recording-button"
                                onMouseDown={handleStart}
                                onMouseUp={handleEnd}
                                onTouchStart={handleStart}
                                onTouchEnd={handleEnd}
                                onContextMenu={(e) => {
                                    //this prevents right-click content menu event on long tab to pop up the menu
                                    e.preventDefault();
                                }}
                                disabled={status === "识别中……"}
                            >
                                <img
                                    style={{
                                        width: "6.25vw",
                                        filter:
                                            status === "识别中……"
                                                ? "grayscale(100%)"
                                                : "none",
                                    }}
                                    src={
                                        recording
                                            ? "img/audio/recording-icon.png"
                                            : "img/audio/non-recording-icon.png"
                                    }
                                    alt={recording ? "聆听中" : "长按说话"}
                                />
                            </button>
                            <div>{recording ? "聆听中" : "长按说话"}</div>
                        </div>
                        {status && <div className="status">{status}</div>}
                    </div>
                )}
            </div>
            {/* <div id="audio-input-list" style={{ backgroundColor: "yellow", color: "black", zIndex: "3", position: "absolute"}}>
     {'status: ' + status}
     {'recorderRef: ' + recorderRef.current?.state}
   </div> */}
        </>
    );
}

export default VoiceToText;
