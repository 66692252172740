import React, { createContext, useContext } from 'react';
import { UseUnityProps } from '../type/unityTypes'

type UnityContextProviderProps = UseUnityProps & {
    children: React.ReactNode
}

const UnityContext = createContext<UseUnityProps | undefined>(undefined);

export const UnityContextProvider: React.FC<UnityContextProviderProps> = ({ children, ...unityProps }) => {
  return <UnityContext.Provider value={unityProps}>{children}</UnityContext.Provider>;
};

export const useUnityContext = (): UseUnityProps => {
  const context = useContext(UnityContext);
  if (!context) {
    throw new Error('useUnityContext must be used within a UnityContextProvider');
  }
  return context;
};