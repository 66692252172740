import React, { createContext, useContext } from 'react';
import { EnvironmentConfigType } from '../type/envTypes'

// Define the shape of your state
type EnvContextProviderProps = EnvironmentConfigType & {
    children: React.ReactNode
}

// Create the context
const EnvContext = createContext<EnvContextProviderProps | undefined>(undefined);

// Create the provider component
export const EnvProvider: React.FC<EnvContextProviderProps> = ({ envs, cur_env, children }) => {
    return <EnvContext.Provider value={{ envs, cur_env, children }}>{children}</EnvContext.Provider>;
  };

// Create a custom hook to simplify accessing the context
export const useEnvContext = () => {
  const context = useContext(EnvContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};