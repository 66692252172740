// Import necessary React and TypeScript types
import { useEffect, useState, useCallback } from "react";
import { useUnityContext } from "../util/UnityContext";

// Define your functional component using the FC (Functional Component) type
const AudioInputList = () => {
    const { addEventListener, removeEventListener, sendMessage } =
        useUnityContext();

    const [audioInputListString, setAudioInputListString] = useState("");
    const [audioOutputListString, setAudioOutputListString] = useState("");

    // const audioRef = useRef(null);

    const listDevices = async (deviceType: string) => {
        // const stream = await navigator.mediaDevices?.getUserMedia({ audio: true, video: false })
        // console.log("获取到的音频信息:", stream);
        const devices = await navigator.mediaDevices?.enumerateDevices?.();

        if (devices) {
            return devices
                .filter((device) => device.kind === deviceType)
                .map((device) => device.label)
                .join("|");
        } else {
            return "";
        }
    };

    const checkDevices = useCallback(async () => {
        const audioElement = new Audio();
        console.log("devices", audioElement);
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(async (stream) => {
                console.log("Microphone access granted:", stream);
                const data2 = await listDevices("audiooutput");
                console.log("output audio list", data2);
                setAudioOutputListString(data2);
                // data to u3d
                const data = await listDevices("audioinput");
                setAudioInputListString(data);
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
            });
    }, []);

    // Your component logic goes here
    const handleGetMicrophoneList = useCallback(async () => {
        console.log("监听到GetMicrophoneList");
        const data = await listDevices("audioinput");
        console.log("input audio list", data);
        setAudioInputListString(data);
    }, []);

    const handleGetSpeakerList = useCallback(async () => {
        console.log("监听到GetSpeakerList");
        const data = await listDevices("audiooutput");
        console.log("output audio list", data);
        setAudioOutputListString(data);
    }, []);

    // 获取麦克风列表
    useEffect(() => {
        if (audioInputListString.length > 0) {
            checkDevices();
            sendMessage(
                "DEVICESCONTROLLER",
                "OnMicrophonesDetected",
                audioInputListString
            );
        }
    }, [audioInputListString, sendMessage, checkDevices]);

    // 获取音箱列表
    useEffect(() => {
        if (audioOutputListString.length > 0) {
            checkDevices();
            sendMessage(
                "DEVICESCONTROLLER",
                "OnSpeakersDetected",
                audioOutputListString
            );
        }
    }, [audioOutputListString, sendMessage, checkDevices]);

    useEffect(() => {
        addEventListener("GetMicrophoneList", handleGetMicrophoneList);
        addEventListener("GetSpeakerList", handleGetSpeakerList);
        return () => {
            removeEventListener("GetMicrophoneList", handleGetMicrophoneList);
            removeEventListener("GetSpeakerList", handleGetSpeakerList);
        };
    }, [
        addEventListener,
        removeEventListener,
        handleGetMicrophoneList,
        handleGetSpeakerList,
    ]);

    // useEffect(() => {
    //     //单独监听 iOS 设备上的自动播放事件
    //     const autoPlayHandler = () => {
    //         if (audioRef.current) {
    //             const promise = (audioRef.current as HTMLAudioElement).play();
    //             promise.catch((error: any) => {
    //                 //在请求已取消的情况下无需发出警告
    //                 if (error.name !== "AbortError") {
    //                     console.error("Error:Could not play audio.", error);
    //                 }
    //             });
    //         }
    //     };
    //     //自动播放时处理
    //     window.addEventListener("touchend", autoPlayHandler, {
    //         once: true,
    //     });
    //     window.addEventListener("click", autoPlayHandler, {
    //         once: true,
    //     });
    //     return () => {
    //         window.removeEventListener("touchend", autoPlayHandler);
    //         window.removeEventListener("click", autoPlayHandler);
    //     };
    // }, []);

    return (
        <div id="audio-input-list" style={{ display: "none" }}>
            {/* <audio ref={audioRef} muted>
                <source src="./sample-3s.mp3" type="audio/mp3" />
            </audio> */}
        </div>
    );
    // return (
    //     <div
    //         id="audio-input-list"
    //         style={{
    //             backgroundColor: "white",
    //             color: "black",
    //             zIndex: "2",
    //             position: "absolute",
    //         }}
    //     >
    //         {"audioInputListString: " + audioInputListString}
    //         {"audioOutputListString: " + audioOutputListString}
    //         <audio ref={audioRef} muted>
    //             <source src="./sample-3s.mp3" type="audio/mp3" />
    //         </audio>
    //     </div>
    // );
};

export default AudioInputList;
